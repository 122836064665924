<template>
  <div>
      <h1>شماره شما مربوط به ایران است  </h1>z
  </div>
</template>

<script>
export default {
  methods:{
    RedircetToDashbord(){
      setTimeout(()=>{
        if (this.$store.getters.userIsLoggedIn == true){
          alert(this.$store.getters.userIsLoggedIn)
          this.$router.push({path:"fa/panel/dashboard"})
        }
        this.$router.push({name:"login"})
      },3000)
    }
  }
}
</script>

<style scoped>

</style>