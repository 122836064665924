import { render, staticRenderFns } from "./phoneNumberFromIran.vue?vue&type=template&id=7d370750&scoped=true&"
import script from "./phoneNumberFromIran.vue?vue&type=script&lang=js&"
export * from "./phoneNumberFromIran.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d370750",
  null
  
)

export default component.exports