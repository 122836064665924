<template>
  <div>
    <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
        v-show="setSessionStatus == null"
    ></v-progress-circular>
    <div v-show="setSessionStatus">
      <v-icon
          dark
          color="green"
          size="100"
          right
      >
        mdi-checkbox-marked-circle
      </v-icon>
      <p class="font-weight-bold text-success">
        {{this.$t('$vuetify.zeroPayment.successSetSession.title')}}
      </p>
      <p>
        {{this.$t('$vuetify.zeroPayment.successSetSession.desc')}}
      </p>
      <v-btn color='primary'>
        {{this.$t('$vuetify.zeroPayment.successSetSession.action')}}
      </v-btn>
    </div>
    <div v-show="setSessionStatus == false">
      <v-icon
          dark
          color="red"
          size="100"
          right
      >
        mdi-cancel
      </v-icon>
      <p class="font-weight-bold text-success">
        {{this.$t('$vuetify.zeroPayment.failedSetSession.title')}}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "response",
  // props: {
  //   processingVerifiedReward: {
  //     required: true,
  //     default: false,
  //   }
  // },
  data: function () {
    return {
      loading: true,
      setSessionStatus: null,
    }
  },
  mounted() {
    this.processForVerifiedReward()
  },
  methods: {
    RedircetToDashbord() {
      setTimeout(() => {
        this.$router.push({name: "dashboard"})
      }, 3000)
    },
    processForVerifiedReward() {
      // let webengageProducts = []
      this.$store.dispatch('processForVerifiedReward').then((resp) => {
        this.setSessionStatus = true

        //web engage
        let webengageProducts = [];
        let conversionRate = resp.data.conversion_rate;
        resp.data.order.products.forEach((item, index) => {
          webengageProducts[`product_id_${(index + 1)}`] = item.id;
          webengageProducts[`product_name_${(index + 1)}`] = item.title;
          webengageProducts[`product_discount_${(index + 1)}`] = parseFloat((item.pivot.discount * conversionRate).toFixed(2));
          webengageProducts[`product_item_count_${(index + 1)}`] = item.pivot.quantity;
          webengageProducts[`product_price_${(index + 1)}`] = parseFloat((item.pivot.price * conversionRate).toFixed(2));
          webengageProducts[`product_session_count_${(index + 1)}`] = item.session_count;
        });
        let webEngageData = {
          currency: 'dollar',
          isFirstPurchase:(resp.data.newCustomer == true)? 'new-customer' : 'old-customer',
          cartsCount:  resp.data.cartsCount,
          cart_id: resp.data.payment.cart_id,
          coupon_code: resp.data.coupon_code,
          amount: parseFloat((resp.data.payment.cart_amount * conversionRate).toFixed(2)),
          ...webengageProducts
        };
        this.sendWebEngagePaymentData('zeroPayment', webEngageData)
        console.log('sent')
        //web engage

      }).catch(() => {
        this.setSessionStatus = false
      }).finally(() => {
        this.RedircetToDashbord();
      })
    },
    sendWebEngagePaymentData(eventName, data) {
      window.webengage.track(eventName, data);
    },

  },
  watch: {
    // processingVerifiedReward(val) {
    //   if (val) {
    //     this.processForVerifiedReward();
    //   }
    // },
  }
}
</script>

<style scoped>

</style>