<template>
  <div class="otp_section">
    <v-content>
      <p class="font-weight-bold">
        {{this.$t('$vuetify.zeroPayment.notif[0]')}}
      </p>
      <p>
        {{this.$t('$vuetify.zeroPayment.notif[1]')}}
        <v-btn text :disabled="reSendOtpStatus" class="text-danger"
               @click="showChangePhoneForm">
          {{this.$t('$vuetify.zeroPayment.notif[2]')}}
        </v-btn>
      </p>
    </v-content>
    <v-row v-if="showFormChangeNumber">
      <v-col cols="4" lg="2">
        <v-btn color="primary"
               elevation="0"
               @click="confirmNewPhoneNumber"
        >
          <v-icon
              dark
              color="white"
              left
          >
            mdi-checkbox-marked-circle
          </v-icon>
          {{this.$t('$vuetify.zeroPayment.confirm')}}

        </v-btn>
      </v-col>

      <v-col cols="8" lg="10">

        <v-form
            ref="editPhone"
        >

          <v-text-field
              @keydown.enter.prevent='confirmNewPhoneNumber'
              append-icon="phone"
              type="tel"
              name="tel"
              v-model="phone"
              outlined
              reverse
              :rules="phoneRules"
              style="direction: ltr;"
              placeholder="example : +15551234567"
              dense

          >
          </v-text-field>
        </v-form>

      </v-col>
    </v-row>

      <div v-if="!showFormChangeNumber">

      <v-form lazy-validation ref="otpForm">
      <v-text-field
          @keydown.enter.prevent='checkOtp'
          outlined
          :disabled="showFormChangeNumber"
          v-model="otpCode"
          counter="6"
          dense
          type="number"
          :rules="[rules.required]"
      ></v-text-field>
    </v-form>

      <v-btn
          color="red"
          class="white--text"
          height="40"
          @click="checkOtp"
          block
          :disabled="showFormChangeNumber"
      >
        {{this.$t('$vuetify.zeroPayment.checkCode')}}
      </v-btn>
      <br>
      <p v-show="timerDisplay">
        {{this.$t('$vuetify.zeroPayment.untilNextSend')}}
        {{ min == -1 ? 0 : min }}:{{ min == -1 ? 0 : sec }}
      </p>
      <v-btn color="primary" :disabled="reSendOtpStatus" @click="sendOtpCode">
        {{this.$t('$vuetify.zeroPayment.reSend')}}
      </v-btn>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import {AsYouType, parsePhoneNumberFromString} from "libphonenumber-js";
import {EventBus} from "@/event-bus";
import {mapGetters} from "vuex";
// import {EventBus} from "@/event-bus";


export default {
  name: 'otp',
  props: {
    sendingOtp: {
      required: true,
      default: false,
    }
  },
  mounted() {
    this.phone = this.profile.cel;
    this.sendOtpCode()
  },
  data: function () {
    return {
      phone: null,
      otpCode: null,
      // phone: ,
      changeNumberStatus: true,
      reSendOtpStatus: true,
      phoneError: false,
      showFormChangeNumber: false,
      rules: {
        required: value => !!value || 'لطفا کد را وارد نمایید',
      },
      // userData: [],
      timerDisplay: false,
      min: 2,
      sec: 59,
      timer: null,
      erroreMessageForSendOtp: "",

    }
  },
  methods: {
    //front Handler
    showChangePhoneForm() {
      this.showFormChangeNumber = true
      this.reSendOtpStatus = true;
    },
    hideChangePhoneForm() {
      this.showFormChangeNumber = false
    },
    //front Handler
    sendOtpCode(setLoader = true) {
      if (setLoader) EventBus.$emit('setLoading', true)
      this.$store.dispatch('sendOtp').then(() => {
        this.resetTimer();
        this.hideChangePhoneForm();
        EventBus.$emit('notify', 'green', undefined, this.$t('$vuetify.zeroPayment.codeSent'));
      }).catch(ex => {
        switch (ex.response.data.data.statusCode) {
          case 1000:
            this.erroreMessageForSendOtp = this.$t('$vuetify.zeroPayment.phoneAcceptedPast'),
            this.$emit('stepHandler', 4)
            break;
          case 1001:
            this.erroreMessageForSendOtp = this.$t('$vuetify.zeroPayment.wrongNumber'),
                this.showChangePhoneForm();
            break;
          case 1002:
            this.erroreMessageForSendOtp = this.$t('$vuetify.zeroPayment.waiting'),
                this.resetTimer()
            break;
          case 1003:
            this.erroreMessageForSendOtp = this.$t('$vuetify.zeroPayment.phoneFromIran'),
                this.showChangePhoneForm();
            break
          case 1004:
            this.erroreMessageForSendOtp = this.$t('$vuetify.zeroPayment.cantConfirm'),
                this.showChangePhoneForm();
            break
        }
        EventBus.$emit('notify', 'red', undefined, this.erroreMessageForSendOtp);
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    checkOtp() {
      if (this.$refs.otpForm.validate()) {
        EventBus.$emit('setLoading', true,)
        this.$store.dispatch('checkOtp', this.otpCode).then((resp) => {
          if (resp.type == "SUCCESS") {
            this.$emit('stepHandler', 4)
            this.$emit('setSessionAfterVerified')
          }
        }).catch(() => {
          EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.zeroPayment.wrongCode'));
        }).finally(() => {
          EventBus.$emit('setLoading', false,)
        })
      }
    },
    timerHandler() {
      this.timerDisplay = true
      this.timer = setInterval(() => {
        if (this.min >= 0) {
          this.sec--;
          if (this.min >= 0 && this.sec < 0) {
            this.sec = 59;
            this.min--;
          }
        } else {
          this.reSendOtpStatus = false;
          this.timerDisplay = false;
          clearInterval();
        }
      }, 1000)
    },
    resetTimer() {
      clearInterval(this.timer)
      this.setTimer(2, 59)
      this.timerHandler();
    },
    setTimer(min, sec) {
      clearInterval();
      this.min = min;
      this.sec = sec;
      this.reSendOtpStatus = true;
      this.timerDisplay = true;
      this.hideChangePhoneForm();
    },
    confirmNewPhoneNumber() {
      if (this.$refs.editPhone.validate()) {
        let phone = this.phone.replaceAll(' ', '');
        const phoneNumber = parsePhoneNumberFromString(phone);
        if (phoneNumber) {
          if (!phoneNumber.isValid()) {
            EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.signupComponent.phoneNumberError'));
            return;
          }
        } else {
          EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.signupComponent.phoneNumberError'));
          return;
        }
        EventBus.$emit('setLoading', true)

        this.$store.dispatch('confirmNewPhoneNumber', phone).then((resp) => {
          this.$store.commit('setUserInfo', resp.data.profile);
          this.sendOtpCode(false);
        }).catch(() => {
          EventBus.$emit('setLoading', false)
        })

      }
    },
  },
  computed: {
    phoneRules() {
      return [
        input => {
          let phone = input.replaceAll(' ', '');
          const phoneNumber = parsePhoneNumberFromString(phone);
          if (phoneNumber) {
            if (!phoneNumber.isValid()) {
              return this.$t('$vuetify.Notify.signupComponent.phoneNumberError');
            } else {
              return true;
            }
          } else {
            return this.$t('$vuetify.zeroPayment.phoneRequired');
          }
        }
      ]
    },
    ...mapGetters({
      profile: 'getUserInfo'
    })


  },
  watch: {
    phone() {
      if (this.phone.startsWith('00')) {
        this.phone = this.phone.substr(2);
        this.phone = '+' + this.phone;
      }

      if (this.phone.startsWith('098')) {
        this.phone = this.phone.substr(1);
        this.phone = '+' + this.phone;
      }

      if (this.phone.startsWith('09')) {
        this.phone = this.phone.substr(1);
        this.phone = '+98' + this.phone;
      }
      this.phone = new AsYouType().input(this.phone);
    },
    // sendingOtp(val) {
    //   if (val == true) {
    //     // this.sendOtpCode()
    //   }
    // }
  },

}
</script>
<style scoped>
.otp_section {
  /*margin: 50% auto;*/
  width: 100%;
  /*background: #0abb87;*/
}

.white--text {
  /*width: 50px !important;*/
}
</style>

