<template>
  <v-app app>
    <v-snackbar
        :id="snackbarId"
        v-model="snackbar.visibility"
        :color="snackbar.color"
        :bottom="snackbar.position === 'bottom'"
        :timeout="snackbar.timeout"
        :top="snackbar.position === 'top'"
    >
      {{ snackbar.message }}
      <v-btn
          color="primary--text"
          @click="snackbar.visibility = false"
          fab
          small
      >
        <v-icon x-small color="red">fas fa-times</v-icon>
      </v-btn>
    </v-snackbar>

    <!--    loading-->
<!--    <v-dialog-->
<!--        v-model="loading"-->
<!--        width="500"-->
<!--        persistent-->
<!--    >-->
<!--      <v-card>-->
<!--        <v-progress-linear-->
<!--            indeterminate-->
<!--            color="primary"-->

<!--        ></v-progress-linear>-->
<!--      </v-card>-->
<!--    </v-dialog>-->

    <!--    loading-->
    <v-content>
      <div style="position: relative" class="preorder-modal">
        <div class=" fill-height">
          <div id="preorder-modal-container" :style="{'overflow-x': largeSize?'hidden' : 'inherit'}">
            <div style="flex-grow: 1"></div>
            <div class="d-flex justify-center align-center stepper_area">
              <div style="width: 100%;">
                <v-stepper v-model="stepperHandler" class="elevation-0 fill-height">
                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <div class="item_stteper fill-height">
                        <div>
                          <SignupComponentHome @changeWindow="updateWindow"
                                               :GASignup="'ga_signup_verify_mobile'"
                                               :verifiedCampagin="true"
                                               preorderLogin="true"
                                               @userLoggedInAfterRegister="userLoggedIn"></SignupComponentHome>
                        </div>

                      </div>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                      <div class="item_stteper">
                        <div
                        >
                          <v-card-text class=" mt-8 justify-center">
                            <v-img class="mx-auto" :src="require('@/assets/simialogovertical.png')" height="63"
                                   width="110"></v-img>
                          </v-card-text>
                          <LoginComponentHome @changeWindow="updateWindow"
                                              :verifiedCampagin="true"
                                              :GALogin="'ga_login_verify_mobile'"
                                              preorderLogin="true"
                                              :subtitle="$t('$vuetify.loginComponent.subTitle')"></LoginComponentHome>

                        </div>
                      </div>
                    </v-stepper-content>
                    <v-stepper-content step="3">
                      <div class="item_stteper">
                        <div v-if="stepperHandler==3">
                          <otp ref="otpSection" :sending-otp="SendingOtp"
                               @setSessionAfterVerified="setSessionAfterVerified"
                               @stepHandler="stepHandler($event)"
                          />

                        </div>
                      </div>

                    </v-stepper-content>
                    <v-stepper-content step="4">
                      <v-card
                          elevation="0"
                          align="center"
                      >

                        <div class="item_stteper">
                          <div v-if="stepperHandler==4">
                            <response/>
                          </div>
                        </div>

                      </v-card>
                    </v-stepper-content>


                    <v-stepper-content step="5">
                      <v-card
                          elevation="0"
                          align="center"
                      >
                        <div class="item_stteper">
                          <phone-number-from-iran ref="PhoneNumberFromIran"/>
                        </div>
                      </v-card>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </div>
            </div>
            <div style="flex-grow: 1"></div>
          </div>
        </div>
      </div>
      <div class='verifiedcampaign'></div>
    </v-content>
  </v-app>
</template>

<script>
import LoginComponentHome from "@/components/LoginComponentHome";
import SignupComponentHome from "@/components/SignupComponentHome";
import Otp from "@/components/verifiedCampaign/otp";
import Response from "@/components/verifiedCampaign/response";
import {EventBus} from "@/event-bus";
// import {AsYouType} from 'libphonenumber-js'
import PhoneNumberFromIran from "@/components/verifiedCampaign/phoneNumberFromIran";


export default {
  components: {PhoneNumberFromIran, Response, Otp, LoginComponentHome, SignupComponentHome},
  data() {
    return {
      stepperHandler: 1,
      snackbar: {
        visibility: false,
        color: 'info',
        position: 'bottom',
        timeout: 5000,
        mode: 'vertical',
        message: 'this is snackbar message',
      },
      loading: false,
      SendingOtp: false,
      processingVerifiedReward: false,
    }
  },
  methods: {
    updateWindow(val) {
      this.stepperHandler = val;
    },
    userLoggedIn(val) {
      this.$store.dispatch("login", val).then(() => {
        this.$store.dispatch('getUserInfo').then(() => {
          this.$store.commit('setLoggedIn');
          this.SendingOtp = true;
          this.stepperHandler = 3;
        })
      })
    },
    setSessionAfterVerified() {
      this.processingVerifiedReward = true
    },
    stepHandler(val) {
      this.stepperHandler = val;
    }
  },
  mounted() {

    if (this.$store.getters.userIsLoggedIn) {
      this.SendingOtp = true;
      this.stepperHandler = 3;
    }
    //EventBus for hanler atfer register or login
    EventBus.$on('userLoggedIn', () => {
      this.SendingOtp = true;
      this.stepperHandler = 3;
    })
    // EventBus.$on('notify', (color, timeout, message) => {
    //   this.snackbar.visibility = false
    //   this.$nextTick(() => {
    //     this.snackbar.visibility = true
    //     this.snackbar.color = color;
    //     this.snackbar.timeout = timeout;
    //     this.snackbar.message = message;
    //   })
    // })
    EventBus.$on('setLoading', (val) => {
      this.loading = val;
    })

  },
  computed: {
    largeSize() {
      return this.innerWidth > 600;
    },
  },


}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 900px) {
  #preorder-modal-container {
    width: 90% !important;
  }

  .v-stepper__content {
    padding: 0px !important;
  }
}


.verifiedcampaign {
  background: red;
  background-image: linear-gradient(75deg, rgba(0, 200, 200, .1), rgba(0, 200, 0, .1)), url('../assets/images/index.png');
  background-repeat: repeat;
  width: 100%;
  /*height: 100%;*/
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: .1;
}

.preorder-modal {
  width: 100%;
  height: 90%;
  position: absolute;
  left: 0;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

#preorder-modal-container {
  border-radius: 12px;
  box-shadow: 0 5px 30px -10px rgba(0, 200, 0, .3);
  border: 1px solid rgba(240, 240, 240, 1);
  min-height: 90vh;
  background-color: white;
  margin: 1% auto;
  padding: 20px;
  width: 40%;

}

.item_stteper {
  /*height: 100%;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: space-around;
  width: 100%;

}

.item_stteper div {
  width: 100%;
}

.stepper_area {
  height: 100% !important;
  flex-direction: column;
}

#preorder-modal-container {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-items: center;
}
</style>